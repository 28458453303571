<template>
  <v-app id="main-app">
    <v-container
      class="fill-height"
      fluid
    >
      <v-card
        v-if="showCard"
        class="mx-auto px-5 pb-7"
        max-width="450px"
        min-width="300px"
        min-height="100px"
        :loading="loading"
        elevation="7"
        rounded
        outlined
      >
        <v-card-title class="justify-center pt-12 mb-12">
          <h3>{{ $t('enterNewPass') }}</h3>
        </v-card-title>
        <v-card-text class="text-center">
          <v-form
            @submit.prevent="changePassword"
          >
            <v-text-field
              v-model="newPassword"
              filled
              rounded
              dense
              label="New password"
              name="new password"
              required
              :type="showNewPassword ? 'text' : 'password'"
              :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :error-messages="errorMessage"
              :rules="[rules.passwordRequired]"
              @click:append="showNewPassword = !showNewPassword"
            />

            <v-text-field
              v-model="confirmPassword"
              filled
              rounded
              dense
              label="Confirm password"
              name="confirm password"
              required
              spellcheck="false"
              :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[rules.confirmPasswordRequired]"
              :type="showConfirmPassword ? 'text' : 'password'"
              @click:append="showConfirmPassword = !showConfirmPassword"
            />
          </v-form>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            class="ma-0"
            color="primary"
            :disabled="checkPasswords"
            @click="changePassword"
          >
            {{ $t('changepassword') }}
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card
        v-else
        class="mx-auto"
        width="344"
      >
        <v-card-text>
          <div class="text--primary text-center">
            {{ message }}
          </div>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            text
            color="primary"
            @click="redirectToLogin"
          >
            {{ $t('backToLogin') }}
          </v-btn>
        </v-card-actions>
      </v-card>

      <v-footer
        color="transparent"
        absolute
        padless
      >
        <v-col
          class="text-center white--text"
          cols="12"
        >
          &copy;{{ new Date().getFullYear() }}
        </v-col>
      </v-footer>
    </v-container>
  </v-app>
</template>

<script>

  export default {
    data () {
      return {
        showCard: true,
        loading: false,
        btnLeft: 'Forgot Password?',
        btnRight: 'Sign In',
        activationEmail: '',
        newPassword: '',
        confirmPassword: '',
        errorMessage: '',
        message: '',
        showNewPassword: false,
        showConfirmPassword: false,
        rules: {
          passwordRequired: value => !!value || 'Enter your new password',
          confirmPasswordRequired: value => value !== this.newPassword ? 'Passwords are not matching' : '',
        },
      }
    },
    computed: {
      checkPasswords () {
        if (this.newPassword !== '') {
          return this.newPassword !== this.confirmPassword
        }
        return true
      },
    },
    methods: {
      async changePassword () {
        const token = this.$router.currentRoute.path.split('/')[3]
        const body = {
          NewPassword: this.confirmPassword,
          ValidationToken: token,
        }
        const response = await this.$store.dispatch('user/changePasswordValidationToken', body)
        if (response.resFlag) {
          this.message = 'Your password is set.'
        } else {
          this.message = 'We could not change your password.Please try again!'
        }
        this.showCard = false
      },
      redirectToLogin () {
        this.$router.replace('/auth/login')
      },
    },
  }
</script>
<style scoped>
    #main-app {
      /* background: rgb(34,193,195);
      background: linear-gradient(0deg, rgba(34,193,195,1) 0%, rgba(253,177,45,1) 100%); */
      background: url('../../../assets/main-page.jpeg') no-repeat;
      background-size: cover;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
    }
</style>
